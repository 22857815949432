import { ButtonRouteLink, OutgoingLink, RouteLink } from '@47ng/chakra-next'
import {
  Box,
  chakra,
  Container,
  Divider,
  Heading,
  HStack,
  Image,
  SimpleGrid,
  Text,
  useColorModeValue
} from '@chakra-ui/react'
import { DimmedText } from 'modules/ui/typography/dimmedText'
import React from 'react'
import { Feature } from './feature'
import { LandingFooter } from './footer'
import { LandingHeader } from './header'
import { Newsletter } from './newsletter'
import { Pricing } from './pricing'

export const LandingPage: React.FC = () => {
  return (
    <>
      <LandingHeader />
      <Box
        as="section"
        textAlign="center"
        px={8}
        pt={36}
        bgGradient="linear(to-b, transparent, card.bg)"
        id=""
      >
        <Heading as="h1" size="4xl" fontWeight="semibold">
          <chakra.span
            bgGradient="linear(to-r, blue.500, purple.500)"
            bgClip="text"
            fontWeight="bold"
          >
            Insight
          </chakra.span>
          , for your eyes only
        </Heading>
        <Text fontSize="3xl" mt={8} mb={4} lineHeight="shorter">
          <chakra.span
            bgGradient="linear(to-r, orange.500, pink.500)"
            bgClip="text"
            fontWeight="bold"
          >
            End-to-end&nbsp;encrypted
          </chakra.span>
          , privacy-first{' '}
          <chakra.span
            bgGradient="linear(to-r, blue.500, teal.500)"
            bgClip="text"
            fontWeight="bold"
          >
            web&nbsp;analytics
          </chakra.span>
        </Text>
        <Text fontSize="lg" color={useColorModeValue('gray.600', 'gray.400')}>
          Meet the first analytics service that <b>can't</b> give away your
          business advantage
        </Text>
        <HStack spacing={6} mt={12} justifyContent="center">
          <ButtonRouteLink
            to="/signup"
            size="lg"
            rounded="full"
            bgGradient="linear(to-r, cyan.400, green.400)"
            color="white"
            px={12}
            _hover={{
              bgGradient: 'linear(to-r, cyan.500, green.500)'
            }}
            _active={{
              bgGradient: 'linear(to-r, cyan.600, green.600)'
            }}
            shadow="base"
          >
            Get started
          </ButtonRouteLink>
          {/* <ButtonRouteLink
            to="/signup"
            size="lg"
            rounded="full"
            variant="outline"
            colorScheme="blue"
            px={12}
            _hover={{
              bgGradient: 'linear(to-r, blue.500, purple.500)',
              color: 'white'
            }}
            _active={{
              bgGradient: 'linear(to-r, blue.600, purple.600)'
            }}
          >
            Demo
          </ButtonRouteLink> */}
        </HStack>
        <Container maxW="6xl" px={4} pt={36} pb={24}>
          <Image
            src="/img/landing/demo.jpg"
            alt="Demo"
            color="white"
            textAlign="center"
            lineHeight="50vw"
            width="100%"
            htmlWidth="2472px"
            htmlHeight="1480px"
            bg="gray.900"
            rounded="lg"
            shadow="dark-lg"
          />
        </Container>
      </Box>
      <Box bg="card.bg" pt={12} pb={24} shadow="lg" id="features">
        <Container as="section" maxW="6xl">
          <SimpleGrid columns={{ base: 1, md: 2 }} rowGap={20} columnGap={16}>
            <Feature title="🔐 End-to-end Encrypted">
              Only you can access your analytics, not even us.
            </Feature>
            <Feature title="🕵️‍♂️ Privacy-First">
              We <b>do not</b> collect personal information on visitors.
            </Feature>
            <Feature title="🍪 GDPR/CCPA Compliant">
              We do not use cookies. No need for an annoying cookie consent
              banner on your website.
            </Feature>
            <Feature title="📶 Works Offline">
              You can analyze past data even when you are offline.
            </Feature>
            <Feature title="✋ Honor DoNotTrack">
              Even though most analytics ignore DNT, we choose to respect it as
              a part of our privacy-first DNA. Visits are registered for DNT
              users, but no additional data is sent.
            </Feature>
            <Feature title="👩‍💻 Open Source">
              Because any piece of code that reaches the public should also be
              public, our codebase is open-source on{' '}
              <OutgoingLink
                href="https://github.com/chiffre-io"
                textDecoration="underline"
              >
                GitHub
              </OutgoingLink>
              .
            </Feature>
            <Feature title="👐 Transparent Operations">
              We publish a{' '}
              <RouteLink to="#newsletter" textDecoration="underline">
                newsletter
              </RouteLink>{' '}
              on the operation of the service, with{' '}
              <RouteLink to="/public/stats" textDecoration="underline">
                public stats
              </RouteLink>{' '}
              and reports.
            </Feature>
            <Feature title="🔑 Robust Encryption">
              We use state-of-the-art encryption algorithms to make sure your
              data and keys are secure.{' '}
              <DimmedText>
                (x25519-xsalsa20-poly1305, ed25519, AES-256-GCM, SRP, PBKDF2)
              </DimmedText>
            </Feature>
            <Feature title="🛡 Secure Access">
              We require a strong master password for user accounts, and provide
              Two Factor Authentication (2FA). Your password is never sent to
              our servers.
              <br />
              <DimmedText>
                Read more about our{' '}
                <RouteLink to="/legal/security" textDecoration="underline">
                  Security Policy
                </RouteLink>
                .
              </DimmedText>
            </Feature>
          </SimpleGrid>
        </Container>
      </Box>
      <Divider />
      <Box py={24} id="newsletter">
        <Container maxW="2xl">
          <Heading as="h2" mb={6}>
            Newsletter
          </Heading>
          <Text my={6}>
            Chiffre is in closed beta, but we're publishing regular updates on
            the project in our newsletter. Take a look at the{' '}
            <OutgoingLink
              href="https://buttondown.email/chiffre.io/archive/"
              textDecoration="underline"
            >
              previous issues
            </OutgoingLink>
            .
          </Text>
          <Newsletter source="landing" />
        </Container>
      </Box>
      <Box
        bg="card.bg"
        py={24}
        mb={8}
        shadow="lg"
        id="pricing"
        borderTopWidth="1px"
      >
        <Container maxW="6xl">
          <Heading as="h2" mb={6} textAlign="center">
            Simple Pricing
          </Heading>
          <Text my={6} textAlign="center">
            Chiffre is free while in beta.
          </Text>
          <Pricing />
        </Container>
      </Box>
      <LandingFooter />
    </>
  )
}
