import { LandingPage as LandingPageView } from 'client/components/landing/landingPage'
import { Page } from 'client/layouts/pages/page'
import type { NextPage } from 'next'
import React from 'react'

const LandingPage: NextPage = () => {
  return (
    <Page title="Chiffre.io" titleTemplate="Chiffre.io">
      <LandingPageView />
    </Page>
  )
}

export default LandingPage
