import { Svg, useColorModeToken } from '@47ng/chakra-next'
import { Button, ButtonProps } from '@chakra-ui/react'
import React from 'react'
import { useQuery } from 'react-query'

interface InstatusProps extends ButtonProps {}

export const Instatus: React.FC<InstatusProps> = ({ ...props }) => {
  const status = useStatus()
  const { text = 'Loading system status...', hue = 'gray' } = status.data ?? {}
  const iconFill = useColorModeToken(hue + '.400', hue + '.300')
  return (
    <Button
      as="a"
      href="https://chiffre.instatus.com"
      target="_blank"
      aria-label="Platform status"
      variant="outline"
      size="sm"
      rounded="lg"
      px={6}
      leftIcon={
        <Svg viewBox="-1 -1 2 2" boxSize={2}>
          <circle cx={0} cy={0} r={1} fill={iconFill} />
        </Svg>
      }
      onMouseEnter={() => status.refetch()}
      {...props}
    >
      {text}
    </Button>
  )
}

// --

function useStatus() {
  return useQuery({
    queryKey: ['external', 'instatus'],
    queryFn: async () => {
      type InstatusResponse = {
        page: {
          status: 'UP' | 'HASISSUES' | 'UNDERMAINTENANCE'
        }
      }
      const res = await fetch('https://chiffre.instatus.com/summary.json')
      const body: InstatusResponse = await res.json()
      return body?.page?.status
    },
    select: status => {
      switch (status) {
        case 'UP':
          return { text: 'All systems operational', hue: 'green' }
        case 'UNDERMAINTENANCE':
          return { text: 'Scheduled maintenance', hue: 'yellow' }
        default:
          return { text: 'Some issues detected', hue: 'red' }
      }
    },
    refetchInterval: 60_000
  })
}
