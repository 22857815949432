import { ButtonRouteLink } from '@47ng/chakra-next'
import {
  Flex,
  FlexProps,
  HStack,
  useBreakpointValue,
  useColorModeValue
} from '@chakra-ui/react'
import { AnimatedLogo } from 'modules/ui/logos/animatedLogo'
import { ColorModeSwitch } from 'modules/ui/system/colorModeSwitch'
import React from 'react'

export interface LandingHeaderProps extends FlexProps {
  showLogin?: boolean
  showSignup?: boolean
}

export const LandingHeader: React.FC<LandingHeaderProps> = ({
  showLogin = true,
  showSignup = true,
  ...props
}) => {
  const buttonSize = useBreakpointValue({ base: 'sm', md: 'sm' })
  const blendFix = {
    mixBlendMode: useColorModeValue('multiply', 'normal')
  }
  return (
    <Flex
      maxW="6xl"
      mx="auto"
      h={16}
      as="header"
      alignItems="center"
      px={4}
      {...props}
    >
      <AnimatedLogo flexShrink={0} />
      <HStack
        isInline
        as="nav"
        display={{ base: 'none', md: 'flex' }}
        spacing={4}
        ml={12}
      >
        <ButtonRouteLink
          to="#features"
          rounded="full"
          px={6}
          variant="ghost"
          sx={blendFix}
        >
          Features
        </ButtonRouteLink>
        <ButtonRouteLink
          to="#pricing"
          rounded="full"
          px={6}
          variant="ghost"
          sx={blendFix}
        >
          Pricing
        </ButtonRouteLink>
      </HStack>
      <HStack isInline as="nav" ml="auto" spacing={[2, 4]}>
        {showLogin && (
          <ButtonRouteLink
            to="/login"
            variant="ghost"
            colorScheme="accent"
            rounded="full"
            px={8}
            size={buttonSize}
            sx={blendFix}
          >
            Log in
          </ButtonRouteLink>
        )}
        {showSignup && (
          <ButtonRouteLink
            to="/signup"
            colorScheme="green"
            rounded="full"
            px={8}
            size={buttonSize}
          >
            Sign up
          </ButtonRouteLink>
        )}
        <ColorModeSwitch />
      </HStack>
    </Flex>
  )
}
