import { Box, BoxProps, Heading, Text } from '@chakra-ui/react'
import React from 'react'

interface FeatureProps extends BoxProps {
  title: string
}

export const Feature: React.FC<FeatureProps> = ({
  title,
  children,
  ...props
}) => {
  return (
    <Box {...props}>
      <Heading as="h3" size="lg" fontWeight="semibold" mb={2}>
        {title}
      </Heading>
      <Text fontSize="lg">{children}</Text>
    </Box>
  )
}
