import { Box, BoxProps, Container, ContainerProps } from '@chakra-ui/react'

export interface LandingSectionProps extends BoxProps {
  containerProps?: ContainerProps
}

export const LandingSection: React.FC<LandingSectionProps> = ({
  children,
  containerProps = {},
  ...props
}) => {
  return (
    <Box as="section" {...props}>
      <Container maxW="6xl" {...containerProps}>
        {children}
      </Container>
    </Box>
  )
}
