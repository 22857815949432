import { ButtonRouteLink, cardProps } from '@47ng/chakra-next'
import {
  Box,
  BoxProps,
  ButtonProps,
  Flex,
  List,
  ListIcon,
  ListItem,
  SimpleGrid,
  Stack,
  StackProps,
  Text
} from '@chakra-ui/react'
import { DimmedText } from 'modules/ui/typography/dimmedText'
import React from 'react'
import { FiCheckCircle } from 'react-icons/fi'

// type BillingCycleRadioButtonProps = {
//   value: string
// }

// const BillingCycleRadioButton: React.FC<BillingCycleRadioButtonProps> = ({
//   value,
//   children,
//   ...props
// }) => {
//   const radioProps = getRadioProps({ value })
//   const { getInputProps, getCheckboxProps } = useRadio(radioProps)
//   const input = getInputProps()
//   const checkbox = getCheckboxProps()

//   return (
//     <Box as="label">
//       <input {...input} />
//       <Button
//         {...checkbox}
//         colorScheme="gray"
//         borderColor="gray.400"
//         variant="outline"
//         shadow="md"
//         _checked={{
//           colorScheme: 'indigo',
//           shadow: 'inner',
//           borderColor: 'indigo.600',
//           variant: 'solid'
//         }}
//         borderWidth="1px"
//         rounded={100}
//         w={24}
//         {...props}
//       >
//         {children}
//       </Button>
//     </Box>
//   )
// }

export enum BillingCycle {
  monthly = 'monthly',
  yearly = 'yearly'
}

// export interface BillingCycleSelectorProps extends StackProps {
//   onCycleChange: (value: BillingCycle) => void
//   cycle: BillingCycle
// }

// export const BillingCycleSwitch: React.FC<BillingCycleSelectorProps> = ({
//   cycle,
//   onCycleChange,
//   ...props
// }) => {
//   return (
//     <Stack isInline alignItems="center" spacing={4} {...props}>
//       <Text fontSize="xl" fontWeight="semibold" color="gray.800" ml={[0, 8]}>
//         Billing
//       </Text>
//       <RadioButtonGroup
//         spacing={0}
//         value={cycle}
//         onChange={onCycleChange as any}
//         flexShrink={0}
//         isInline
//       >
//         <BillingCycleRadioButton
//           value={BillingCycle.monthly}
//           borderRightWidth={0}
//           borderTopRightRadius={0}
//           borderBottomRightRadius={0}
//         >
//           Monthly
//         </BillingCycleRadioButton>
//         <BillingCycleRadioButton
//           value={BillingCycle.yearly}
//           borderLeftWidth={0}
//           borderTopLeftRadius={0}
//           borderBottomLeftRadius={0}
//         >
//           Yearly
//         </BillingCycleRadioButton>
//       </RadioButtonGroup>
//       <Text color="gray.600" display={['none', 'block']}>
//         Save 2 months
//       </Text>
//     </Stack>
//   )
// }

// --

export interface PricingItemData {
  plan: string
  cycle: BillingCycle
  title: string
  subtitle: string
  price: number
  features: {
    available: (string | React.ReactElement)[]
    comingSoon?: (string | React.ReactElement)[]
  }
  buttonVariant?: ButtonProps['variant']
  buttonText?: string
}

export interface PricingItemProps
  extends PricingItemData,
    Omit<BoxProps, 'title'> {}

export const PricingItem: React.FC<PricingItemProps> = ({
  plan,
  cycle,
  title,
  subtitle,
  price,
  features,
  buttonVariant = 'solid',
  buttonText = 'Get started',
  ...props
}) => {
  return (
    <Box
      minW={['90vw', 'sm', 'sm', 'xs']}
      display="flex"
      flexDir="column"
      borderWidth="1px"
      {...cardProps}
      px={0}
      overflow="hidden"
      transition="all 0.2s ease"
      _hover={{
        shadow: 'lg'
      }}
      {...props}
    >
      <Box px={8} pb={4} textAlign="center">
        <Text color="text.contrasted" fontWeight="semibold" fontSize="2xl">
          {title}
        </Text>
        <DimmedText>{subtitle}</DimmedText>
        <Text color="text.contrasted" fontWeight="semibold" fontSize="4xl">
          {cycle === BillingCycle.yearly ? price * 10 : price}€
          <DimmedText as="span" fontWeight="normal">
            {' '}
            / {cycle === BillingCycle.yearly ? 'year' : 'month'}
          </DimmedText>
        </Text>
      </Box>
      <Flex px={4} pt={4} borderTopWidth="1px" flexDirection="column" h="100%">
        <List spacing={2} px={2} mb={8}>
          {features.available.map(feature => (
            <ListItem key={feature.toString()}>
              <ListIcon as={FiCheckCircle} color="green.500" mt={-1} />
              {feature}
            </ListItem>
          ))}
          {(features.comingSoon?.length || 0) > 0 && (
            <ListItem color="text.dim">Coming soon:</ListItem>
          )}
          {features.comingSoon?.map(feature => (
            <ListItem key={feature.toString()} color="text.dimmer">
              <ListIcon as={FiCheckCircle} color="text.dimmest" mt={-1} />
              {feature}
            </ListItem>
          ))}
        </List>
        <ButtonRouteLink
          onClick={() => {
            if (!window.chiffre) {
              return
            }
            window.chiffre.sendString({
              name: 'landing:plan:clicked',
              value: `${plan}:${cycle}`
            })
          }}
          to={`/signup?plan=${plan}&billing=${cycle}`}
          colorScheme="green"
          variant={buttonVariant}
          w="100%"
          size="lg"
          mt="auto"
        >
          {buttonText}
        </ButtonRouteLink>
      </Flex>
    </Box>
  )
}

const EventLimitEmphasis = ({ ...props }) => (
  <Text as="b" fontSize="1.2em" {...props} />
)

export const Pricing: React.FC<StackProps> = ({ ...props }) => {
  const [cycle, setCycle] = React.useState(BillingCycle.monthly)
  return (
    <Stack alignItems="center" spacing={12} {...props}>
      <Text fontSize="sm" fontStyle="italic">
        Prices, limits and features are subject to changes during the preview
        period.
      </Text>
      {/* <BillingCycleSwitch cycle={cycle} onCycleChange={setCycle} /> */}
      <SimpleGrid justifyContent="center" spacing={4} columns={[1, 1, 1, 3]}>
        <PricingItem
          plan="free"
          cycle={cycle}
          title="Free"
          price={0}
          subtitle="For small blogs &amp; personal pages"
          buttonVariant="outline"
          buttonText="Get started"
          features={{
            available: [
              'End-to-end encryption',
              '3 websites',
              <>
                <EventLimitEmphasis>200</EventLimitEmphasis> daily events{' '}
                <DimmedText as="span" fontSize="xs">
                  (per website)
                </DimmedText>
              </>,
              'Privacy-first analytics',
              'Referring domains',
              'Browser, OS & device types',
              'Screen sizes',
              'Respects DoNotTrack'
            ],
            comingSoon: []
          }}
        />
        <PricingItem
          plan="small"
          cycle={cycle}
          title="Personal"
          price={12}
          subtitle="For startups &amp; freelancers"
          buttonVariant="solid"
          features={{
            available: [
              'End-to-end encryption',
              '5 websites',
              <>
                <EventLimitEmphasis>2,000</EventLimitEmphasis> daily events{' '}
                <DimmedText as="span" fontSize="xs">
                  (per website)
                </DimmedText>
              </>,
              'Goals tracking',
              'Custom metrics',
              'Email support'
            ],
            comingSoon: ['Alerts (Slack, email)', 'Weekly reports']
          }}
        />
        <PricingItem
          plan="large"
          cycle={cycle}
          title="Business"
          price={72}
          subtitle="For businesses with greater data needs"
          buttonVariant="solid"
          features={{
            available: [
              'End-to-end encryption',
              'unlimited websites',
              <>
                <EventLimitEmphasis>100k</EventLimitEmphasis> daily events{' '}
                <DimmedText as="span" fontSize="xs">
                  (per website)
                </DimmedText>
              </>,
              'Goals tracking',
              'Custom metrics',
              'Premium support'
            ],
            comingSoon: ['Alerts (Slack, email)', 'Weekly reports']
          }}
        />
      </SimpleGrid>
      {/* <Stack>
          <Text>FAQ</Text>
          <Text>What is an event ?</Text>
        </Stack> */}
    </Stack>
  )
}
