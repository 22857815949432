import { OutgoingLink, RouteLink } from '@47ng/chakra-next'
import {
  Heading,
  List,
  ListItem,
  SimpleGrid,
  Stack,
  StackProps,
  useColorModeValue
} from '@chakra-ui/react'
import { AnimatedLogo } from 'modules/ui/logos/animatedLogo'
import { DimmedText } from 'modules/ui/typography/dimmedText'
import React from 'react'
import { Instatus } from './instatus'
import { LandingSection, LandingSectionProps } from './section'

export interface LandingFooterProps extends LandingSectionProps {}

export const LandingFooter: React.FC<LandingFooterProps> = ({ ...props }) => {
  return (
    <LandingSection as="footer" {...props}>
      <SimpleGrid minChildWidth="150px" spacing={12} py={12}>
        <AboutSection />
        <LinksSection
          title="Helpful Links"
          links={[
            { label: 'About', href: '/#' },
            { label: 'Features', href: '/#features' },
            { label: 'Pricing', href: '/#pricing' },
            // { label: 'FAQ', href: '/faq' },
            // { label: 'Blog', href: '/blog' },
            {
              label: 'Contact',
              href: 'mailto:contact+landing@chiffre.io',
              isExternal: true
            }
          ]}
        />
        <LinksSection
          title="Follow us"
          links={[
            {
              label: 'Twitter',
              href: 'https://twitter.com/chiffre_io',
              isExternal: true
            },
            {
              label: 'GitHub',
              href: 'https://github.com/chiffre-io/chiffre',
              isExternal: true
            },
            {
              label: 'IndieHackers',
              href: 'https://www.indiehackers.com/product/chiffre-io',
              isExternal: true
            }
            // {
            //   label: 'ProductHunt',
            //   href: 'https://www.producthunt.com/posts/chiffre.io',
            //   isExternal: true
            // }
          ]}
        />
        <LinksSection
          title="Transparency"
          links={[
            {
              label: 'Open Source',
              href: 'https://github.com/chiffre-io',
              isExternal: true
            },
            {
              label: 'Platform Stats',
              href: '/public/stats'
            },
            {
              label: 'Newsletter',
              href: 'https://buttondown.email/chiffre.io',
              isExternal: true
            }
          ]}
        />
        <LinksSection
          title="Legal"
          links={[
            {
              label: 'Terms of Service',
              href: '/legal/terms-of-service'
            },
            {
              label: 'Privacy Policy',
              href: '/legal/privacy'
            },
            {
              label: 'Security Policy',
              href: '/legal/security'
            }
          ]}
        />
      </SimpleGrid>
    </LandingSection>
  )
}

// --

const AboutSection = () => {
  const instatusFrameVariant = useColorModeValue('light-sm', 'dark-sm')
  return (
    <Stack spacing={4}>
      <AnimatedLogo />
      <DimmedText>
        Made with 💚 by&nbsp;
        <OutgoingLink href="https://francoisbest.com">
          François&nbsp;Best
        </OutgoingLink>
        <br />
        in Grenoble, France 🏔
      </DimmedText>
      <Instatus />
    </Stack>
  )
}

// --

interface LinkDescriptor {
  label: string
  href: string
  isExternal?: true
}

interface LinksSectionProps extends StackProps {
  title: string
  links: LinkDescriptor[]
}

const LinksSection: React.FC<LinksSectionProps> = ({
  title,
  links,
  ...props
}) => {
  const linkColor = useColorModeValue('gray.600', 'gray.400')
  return (
    <Stack {...props}>
      <Heading as="h4" fontSize="md" fontWeight="semibold" mb={2}>
        {title}
      </Heading>
      <List spacing={2}>
        {links.map(({ label, href, isExternal }) => (
          <ListItem key={href} color={linkColor}>
            {isExternal ? (
              <OutgoingLink href={href}>{label}</OutgoingLink>
            ) : (
              <RouteLink to={href}>{label}</RouteLink>
            )}
          </ListItem>
        ))}
      </List>
    </Stack>
  )
}
